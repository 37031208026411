import * as React from 'react';
import { useEffect, useState } from 'react';
import TailwindDebugBreakpoints from 'tailwind-debug-breakpoints-react';
import { AdminBarProvider } from './src/AdminBarContext';

import './src/styles/app.css';
import 'instantsearch.css/themes/satellite.css';
import type { GatsbyBrowser } from 'gatsby';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
	element,
}) => <AdminBarProvider>{element}</AdminBarProvider>;

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
	element,
}) => {
	const [tw, setTw] = useState<any>(null);

	useEffect(() => {
		if (process.env.NODE_ENV === 'development') {
			async function injectTwDebug() {
				const { default: resolveConfig } = await import(
					'tailwindcss/resolveConfig'
				);
				const { default: config } = await import('./tailwind.config');
				setTw(resolveConfig(config));
			}

			injectTwDebug();
		}
	}, []);

	if (tw) {
		return (
			<div>
				{element}
				<TailwindDebugBreakpoints screens={tw?.theme?.screens} />
			</div>
		);
	}

	return element;
};
