import * as React from 'react';
import { useEffect, useState } from 'react';
import { isLoggedIn } from './utils/restricted';

type AdminBarContextProps = {
	showAdminBar: boolean;
};

const AdminBarContext = React.createContext<AdminBarContextProps | undefined>(
	undefined
);

export function AdminBarProvider({ children }: { children: React.ReactNode }) {
	const [showAdminBar, setShowAdminBar] = useState(false);

	useEffect(() => {
		setShowAdminBar(process.env.NODE_ENV === 'development' || isLoggedIn());
	}, []);

	return (
		<AdminBarContext.Provider value={{ showAdminBar }}>
			{children}
		</AdminBarContext.Provider>
	);
}

export function useAdminBar() {
	return React.useContext(AdminBarContext);
}
