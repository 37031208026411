import * as React from 'react';

export function isPreviewSite() {
	return !!(
		process.env.NODE_ENV === 'development' && process.env.GATSBY_PREVIEW
	);
}

export function isLoggedIn() {
	return !!document?.cookie.match(/^(.*;)?\s*authed\s*=\s*[^;]+(.*)?$/);
}

export function restricted() {
	return isPreviewSite() && !isLoggedIn();
}

export function UnAuthorized() {
	return React.createElement(
		'div',
		{ className: 'p-20 text-center' },
		'unauthorized'
	);
}
